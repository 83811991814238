/* stylelint-disable */
.aside-news {
  margin-bottom: 20px;
}
@media screen and (max-width: 1230px) {
  .aside-news--worksheets {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
  }
}
.aside-news__item {
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  -webkit-transition: -webkit-box-shadow 0.5s, -webkit-transform 0.15s;
  transition: -webkit-box-shadow 0.5s, -webkit-transform 0.15s;
  transition: box-shadow 0.5s, transform 0.15s;
  transition: box-shadow 0.5s, transform 0.15s, -webkit-box-shadow 0.5s, -webkit-transform 0.15s;
}
.aside-news__item:hover {
  -webkit-box-shadow: 0 4px 12px rgba(34, 34, 34, 0.1);
          box-shadow: 0 4px 12px rgba(34, 34, 34, 0.1);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.aside-news__item:hover .aside-news__title {
  text-decoration: underline;
}
.aside-news__item:last-child {
  margin-bottom: 0;
}
.aside-news__preview {
  position: relative;
  height: 125px;
  background-color: #000;
}
.aside-news__preview img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.aside-news__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 8px 8px 16px;
  background-color: #fff;
}
.aside-news__category {
  display: block;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 1.1;
  color: #267f8c;
}
.aside-news__title {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #000;
}
.aside-news__time {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #adadaf;
}
.aside-news__time-webinar {
  position: absolute;
  background-color: rgba(41, 41, 41, 0.9);
  border-radius: 16px;
  bottom: 8px;
  right: 8px;
  padding: 2px 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #fff;
}
.aside-news__info {
  font-size: 14px;
}
.aside-news__info > * {
  position: relative;
  display: inline-block;
  margin-right: 14px;
  padding-left: 20px;
  color: #a9a9a9;
}
.aside-news__info > ::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.aside-news__visits::before {
  background-image: url("https://fs23.infourok.ru/file/0267-00088de6-eadb07fa.svg");
}
.aside-news__comments::before {
  background-image: url("https://fs23.infourok.ru/file/0268-00088de7-e1d16d10.svg");
}
.aside-news__date::before {
  background-image: url("https://fs23.infourok.ru/file/026a-00088de9-8e9b5cd2.svg");
}
/*# sourceMappingURL=aside-news.css.map */
