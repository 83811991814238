/* stylelint-disable */
@mixin line-clamp($property) {
    -webkit-line-clamp: $property;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.aside-news {
    margin-bottom: 20px;

    &--worksheets {
        @media screen and (max-width: 1230px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 0;
        }
    }

    &__item {
        display: block;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 4px;
        transition: box-shadow 0.5s, transform 0.15s;

        &:hover {
            box-shadow: 0 4px 12px rgba(34, 34, 34, 0.1);
            transform: translateY(-3px);

            .aside-news__title {
                text-decoration: underline;
            }
        }
    }

    &__item:last-child {
        margin-bottom: 0;
    }

    &__preview {
        position: relative;
        height: 125px;
        background-color: #000;
    }

    &__preview img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 8px 8px 16px;
        background-color: #fff;
    }

    &__category {
        display: block;
        margin-bottom: 9px;
        font-size: 14px;
        line-height: 1.1;
        color: #267f8c;
    }

    &__title {
        @include line-clamp(4);

        margin-bottom: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        color: #000;
    }

    &__time {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        color: #adadaf;
    }

    &__time-webinar {
      position: absolute;
      background-color: rgba(41, 41, 41, 0.9);
      border-radius: 16px;
      bottom: 8px;
      right: 8px;
      padding: 2px 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      color: #fff;
    }

    &__info {
        font-size: 14px;
    }

    &__info > * {
        position: relative;
        display: inline-block;
        margin-right: 14px;
        padding-left: 20px;
        color: #a9a9a9;
    }

    &__info > ::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 16px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateY(-50%);
    }

    &__visits::before {
        background-image: url("https://fs23.infourok.ru/file/0267-00088de6-eadb07fa.svg");
    }

    &__comments::before {
        background-image: url("https://fs23.infourok.ru/file/0268-00088de7-e1d16d10.svg");
    }

    &__date::before {
        background-image: url("https://fs23.infourok.ru/file/026a-00088de9-8e9b5cd2.svg");
    }
}
